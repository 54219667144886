.navContainer {
    background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-6));
}

.link {
    display: block;
    line-height: 1;
    padding: rem(8px) rem(12px);
    border-radius: var(--mantine-radius-sm);
    text-decoration: none;
    color: light-dark(var(--mantine-color-dark-6), var(--mantine-color-dark-0));
    font-size: var(--mantine-font-size-sm);
    font-weight: 500;

    @mixin hover {
        background-color: light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
    }

    [data-mantine-color-scheme] &[data-active] {
        background-color: var(--mantine-color-blue-9);
        color: var(--mantine-color-white);
    }
}

.burger {
    border-radius: var(--mantine-radius-md);

    @mixin hover {
        background-color: light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4));
    }
}