.lightMode {
    @mixin dark {
        display: none;
    }

    @mixin light {
        display: flex;
    }
}

.darkMode {
    @mixin light {
        display: none;
    }

    @mixin dark {
        display: flex;
    }
}

/* theme fade transition */
.transitionThemeColors {
    transition: color .3s, background-color .3s;
}